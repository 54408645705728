import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './TestimonialSlider.scss';
import Carousel from '../../Carousel';
import { slugify, decodeEntities } from '../../../utils/helpers';
import { Image } from '../../Image';

export const TestimonialItem = props => {
  const {showLogo, slide, className} = props
  return (
    <div className={`${className}${showLogo ? ' logo-testimonial' : ' testimonial'}`}>
      <div className="wrap">
        <div className="inner">
          {showLogo &&<div className="logo">
            <div className="inner-logo"><Image src={slide.acf.logo && slide.acf.logo}/></div>
          </div>}
          <div className="content">
            {slide.content && <p dangerouslySetInnerHTML={{ __html: decodeEntities(slide.content) }}></p>}
            {slide.acf.name && <span className="name"><strong>{decodeEntities(slide.title)}</strong> - { decodeEntities(slide.acf.name) }</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

class ThisSlider extends Component {
  render () {
    const { testimonials, showLogo, title, listView } = this.props
    const settings = {
      container: `${slugify(title)}-slug`,
      nav: true,
      controls:false,
      mouseDrag: true,
      items: 1,
      loop: true,
      autoHeight: true
    };
    return (
      <div className={!listView ? "carousel-wrap" : "list-wrap"}>
        {!listView &&
          <Carousel settings={settings}>
            {testimonials.map((slide, slide_count) => slide_count < 6 &&
              <TestimonialItem key={slide_count} slide={slide} showLogo={showLogo} className="slide" />
            )}
          </Carousel>
        }
        {listView &&
          testimonials.map((slide, slide_count) => (
            <TestimonialItem key={slide_count} slide={slide} showLogo={showLogo} className="slide" />
          ))
        }
      </div>
    )
  }
}

export const TestimonialPass = props => {
  const { title, showLogo, data, listView } = props;
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials;
  const [hasMounted, setHasMounted] = React.useState(false);
  
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <section className={`testimonial-slider`}>
      {title && <h3 className="section-title">{decodeEntities(title)}</h3>}
      <ThisSlider testimonials={posts} showLogo={showLogo} title={title} listView={listView} />
    </section>
  )
}

export const TestimonialSlider = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials(sort: { fields: date, order: ASC }) {
            nodes {
              content
              slug
              title
              acf {
                logo {
                  source_url
                }
                name
                position
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  );
}
